.weightloss--container {
  width: 100%;
  background: var(--color-primary-lighter);
  padding: 5rem 0;
  border-radius: 25rem 0 0 0;
  @media (max-width: 650px) {
    border-radius: 15rem 0 0 0;
  }
  .weightloss {
    max-width: 140rem;
    margin: auto;

    display: flex;
    flex-direction: column;
    gap: 3rem;

    section {
      max-width: 150rem;
      margin: auto;
      display: flex;
      gap: 3rem;
      justify-content: space-between;
      padding: 0 4rem;
      @media (max-width: 650px) {
        flex-direction: column;
      }

      .left {
        width: 50%;

        display: flex;
        flex-direction: column;

        gap: 5rem;
        h2 {
          text-align: left;
          max-width: 30rem;
          &:before {
            left: 4rem;
          }
          &::after {
            position: absolute;
          }
          @media (max-width: 1300px) {
            padding-top: 4rem;
          }
        }
        p {
          font-size: 2rem;
        }
        .image {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: -2rem;
            left: -2rem;
            width: 15rem;
            height: 15rem;
            background: var(--color-primary);
            border-radius: 50%;
          }
          img {
            max-height: 65rem;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
          }
        }
        @media (max-width: 650px) {
          width: 100%;
        }
      }
      .right {
        width: 50%;
        padding: 1rem;
        padding-left: 3rem;
        max-width: 50rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: flex-end;
        .section--row {
          position: relative;
          &.section--row1,
          &.section--row2 {
            padding-bottom: 10rem;
          }
          &.section--row1::after,
          &.section--row2::after {
            position: absolute;
            content: "";
            // top: calc(100% - 11rem);
            // left: 50%;
            top: 0;
            left: -5%;
            width: 0;
            height: 100%;
            border: 2.5px dashed rgba(219, 219, 219, 0.753);
            background: transparent;
            border-radius: 5rem;
            z-index: 0;
          }
          .element {
            position: absolute;
            width: 7rem;
            height: 7rem;
            background: var(--color-bg);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 4rem;
            color: #ffffff91;
            z-index: 1;
            transform: translate(-80%, -40%);
          }
          h3 {
            color: var(--color-primary);
          }
          h3,
          p {
            text-align: left;
            z-index: 10;
            position: relative;
          }
        }
        @media (max-width: 650px) {
          width: 100%;
        }
      }
    }
  }
}
