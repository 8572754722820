form {
  padding: 4rem;
  border-radius: 2rem;
  width: 100%;
  min-width: 35rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .form--group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      font-size: 1.4rem;
      text-transform: capitalize;
      color: var(--color-secondary);
      font-weight: 500;
    }
    input,
    textarea {
      border: none;
      outline: none;
      border-bottom: 1px solid var(--color-primary);
      background: transparent;
      padding: 1rem 0.5rem;
      font-family: Jost, sans-serif;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      color: var(--color-secondary);
    }
  }

  position: relative;
  z-index: 10;
  & > * {
    position: relative;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0rem;
    width: 100%;
    height: 100%;
    background: var(--color-primary-lighter);

    border-radius: 0 50% 0 50%;
  }
  .btn {
    align-self: center;
  }
  .errorMessage {
    color: rgb(185, 1, 1);
    font-size: 1.3rem;
    text-transform: capitalize;
  }
}
