.contact--container {
  width: 100%;

  // background-image: linear-gradient(
  //   to bottom,
  //   #fff 0%,
  //   #fff 50%,
  //   var(--color-primary-lighter) 50%,
  //   var(--color-primary-lighter) 100%
  // );
  background: var(--color-primary-lighter);
  padding: 5rem;
  @media (max-width: 400px) {
    padding: 0rem;
  }
  .contact {
    max-width: 130rem;

    background: var(--color-bg);

    margin: auto;

    height: 100%;
    border-radius: 5rem;
    display: flex;
    gap: 5rem;
    padding: 4rem;
    justify-content: space-around;
    @media (max-width: 810px) {
      flex-wrap: wrap;
      padding: 0rem;
    }
    .left {
      width: 40%;
      @media (max-width: 810px) {
        width: 80%;
      }
      h2 {
        text-align: left;
        margin-bottom: 2rem;
        &:before {
          left: 3rem;
        }
      }
    }
  }
}
