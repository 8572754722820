:root {
  --color-primary: #318c78;
  // --color-primary-darker: #1e987b;
  --color-primary-lighter: #e9faf9;
  --color-primary-lighter-2: #e1fdfb;
  --color-secondary: hsl(167, 81%, 10%);
  --color-bg: #67dbd0;
  --color-secondary-lighter: #16463b;
  --color-text: #5c5c5c;
  --color-text-secondary: #727272;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  font-family: Jost, sans-serif;
  width: 100vw;
}
.btn {
  border: none;
  outline: none;
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  padding: 1.5rem 2.5rem;
  background: linear-gradient(
    to right,
    var(--color-bg),
    var(--color-secondary-lighter)
  );
  border-radius: 2.5rem;
  font-family: "Jost", sans-serif;
  cursor: pointer;
  transition: all linear 1s;
  &:hover {
    transition: all linear 1s;
    background: linear-gradient(
      to bottom,
      var(--color-secondary-lighter),
      var(--color-bg)
    );
  }
}

h3 {
  font-size: 2.6rem;
  font-weight: 500;
  text-transform: capitalize;
}
h2 {
  font-size: 4.8rem;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--color-secondary);
  text-align: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    height: 5px;
    width: 5rem;
    background: var(--color-secondary);
    border-radius: 1rem;
  }
}
p {
  font-size: 1.6rem;
  text-transform: capitalize;
}
.sticky-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  transition: transform 0.2s linear;
  &:hover {
    .whatsapp-icon {
      transform: scale(0.9);
    }
  }
  .whatsapp-icon {
    color: white;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background: #25d366;
    padding: 1rem;
    box-shadow: var(--box-shadow);
  }
}

.toast-font-size {
  font-size: 1.4rem;
  width: max-content;
  color: var(--color-secondary);
  text-transform: capitalize;
}

@media (max-width: 1400px) {
  html {
    font-size: 57%;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 300px) {
  html {
    font-size: 45%;
  }
}
