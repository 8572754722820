.homeProducts {
  width: 100%;
  max-width: 150rem;
  margin: auto;

  padding: 7rem 0;
  .homeProducts--swiper {
    width: 100%;
    padding: 3rem;
    .swiper-button-prev {
      left: 0;
      color: var(--color-primary);
    }
    .swiper-button-next {
      right: 0;
      color: var(--color-primary);
    }
    .slide {
      padding: 1rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      background: #f5f5f5;
      border-radius: 2rem;
      height: 60rem;

      .img {
        padding: 1rem;
        height: 30rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          background: white;
          max-width: 100%;
          height: 100%;
        }
      }
      .name {
        text-align: center;
      }
      ul {
        padding: 2rem 0;
        li {
          list-style: none;
          font-size: 1.5rem;
          display: flex;

          align-items: center;
          gap: 1rem;
          text-transform: capitalize;
          .icon {
            min-width: 1.4rem;
            min-height: 1.4rem;
            color: var(--color-primary);
          }
        }
      }
    }
  }
  // .clicklink {
  //   display: flex;
  //   justify-content: center;
  //   a {
  //     text-decoration: none;
  //     font-size: 1.6rem;
  //     text-transform: uppercase;
  //     color: var(--color-primary);
  //     font-weight: 600;
  //     transition: all 1s;
  //     position: relative;
  //     padding: 0.2rem;
  //     &:before {
  //       content: "";
  //       position: absolute;
  //       left: 0;
  //       bottom: 0;
  //       height: 1px;
  //       width: 3rem;
  //       background: #000;
  //     }
  //   }

  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }
}
