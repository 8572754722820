.nav {
  width: 100vw;
  max-width: 150rem;

  margin: auto;
  display: flex;
  padding: 1rem 4rem;
  height: 9rem;
  justify-content: space-between;
  align-items: center;

  .menu {
    font-size: 2.5rem;
    color: var(--color-secondary);
    display: none;
    z-index: 200;
    @media (max-width: 990px) {
      display: inline-block;
    }
  }
  .nav--links {
    display: flex;
    gap: 3rem;
    list-style: none;
    align-items: center;
    height: 100%;

    .nav--link {
      text-decoration: none;
      font-size: 1.6rem;
      color: var(--color-text);
      line-height: 24px;
      letter-spacing: 0.005em;
      font-weight: 500;
      text-transform: capitalize;
      position: relative;
      display: flex;
      align-items: center;
      // height: 100%;
      a {
        text-decoration: none;
        color: var(--color-text);
        &.navActive {
          color: var(--color-primary);
        }
      }
      .nav--heading {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .nav--link__sub {
        border-radius: 2rem;
        z-index: 200;
        background: #ffffff;
        border: 1px solid rgb(218, 216, 216);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: 50%;
        padding: 1rem;
        transform: translateX(-50%);
        width: max-content;

        li {
          padding: 0.5rem 1rem;
          list-style: none;

          a {
            color: var(--color-primary);
            font-size: 1.3rem;
            &:hover {
              color: var(--color-secondary);
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid rgb(218, 216, 216);
          }
        }

        &.active {
          visibility: visible;
        }
      }
    }
    .nav--link:has(a.subNavActive) > span {
      color: var(--color-primary);
    }
  }
}
.logo {
  height: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;

  gap: 0.5rem;
  img {
    max-height: 6rem;
    height: 100%;
  }
  &--name {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      color: transparent;
      line-height: 1;

      font-weight: 700;
      &:nth-child(1) {
        background-image: linear-gradient(to top right, #11ac8d, #03ffe6);
        background-clip: text;
        font-size: 2.7rem;
      }
      &:nth-child(2) {
        color: transparent;
        background-clip: text;
        background-image: linear-gradient(
          to right,

          var(--color-secondary),
          #216455
        );
        font-size: 1.7rem;
        text-transform: uppercase;
      }
    }
  }
}
@media (max-width: 990px) {
  .nav .nav--links {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    background: var(--color-primary-lighter);
    border-left: 1px solid var(--color-primary);
    height: 100vh;
    width: 35rem;
    z-index: 100;
    &.open {
      display: flex;
    }
  }
}
