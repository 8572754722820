.footer--container {
  background: var(--color-primary-lighter);
  padding-bottom: 2rem;
  .footer {
    .row {
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 1rem;
      gap: 2rem;
      &.row1 {
        flex-wrap: wrap;
        background: rgb(255, 255, 255);
      }
      &.row2 {
        max-width: 125rem;
        margin: 0 auto;
        @media (max-width: 810px) {
          flex-wrap: wrap;
          margin: 0;
        }
      }

      //   flex-wrap: wrap;
      .box {
        max-width: 40rem;
        height: 12rem;
        text-decoration: none;
        width: 100%;
        padding: 1rem;

        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;

        .icon {
          width: 5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
        &--right {
          color: transparent;
          background-clip: text;
          background-image: linear-gradient(to top right, #09886f, #03ffe6);
          p {
            font-size: 2rem;
            &.heading {
              font-size: 1.7rem;
              color: black;
            }
          }
        }
      }
      .col {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        p {
          //   max-width: 30rem;
          text-align: justify;
        }

        h3 {
          color: transparent;
          background-clip: text;
          background-image: linear-gradient(to top right, #09886f, #03ffe6);
        }
        p {
          color: #189c82;
          &.note {
            font-style: italic;
            font-weight: bold;
            text-transform: none;
          }
        }

        .logo {
          align-self: flex-start;
          span {
            background-image: linear-gradient(to top right, #09886f, #03ffe6);
            background-clip: text;
          }
        }
        .socialmedia {
          display: flex;
          gap: 2rem;
          margin-left: 1rem;

          a {
            color: var(--color-secondary);
            background: var(--color-bg);
            padding: 1rem;
            border-radius: 50%;
            .icon {
              font-size: 3rem;
              transition: all 0.3s;
            }
            &:hover {
              .icon {
                color: var(--color-primary);
              }
            }
          }
        }
      }
      .copyright {
        color: var(--color-primary);

        width: 100%;
        font-size: 1.2rem;
      }
    }
  }
}
