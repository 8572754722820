.treatments--container {
  padding: 5rem 0;
  width: 100%;
  .treatments {
    max-width: 150rem;
    margin: auto;
    padding: 1rem;

    .cards {
      width: 100%;
      display: flex;
      gap: 5rem;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 4rem;
      .card {
        width: 100%;
        max-width: 35rem;
        background: #f5f5f5;
        border-radius: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 2rem;

        .img {
          max-width: 40rem;
          height: 35rem;

          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }
        .name {
          text-align: center;
        }
        ul {
          padding: 2rem;
          li {
            list-style: none;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            .icon {
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }
}
