.box--container {
  width: 100%;
  // max-width: 140rem;
  position: relative;
  padding: 0 5rem;
  // top: -25rem;
  left: 50%;
  transform: translateX(-50%);
  background-image: linear-gradient(
    to bottom,
    var(--color-primary-lighter) 0%,
    var(--color-primary-lighter) 40%,
    #fff 40%,
    #fff 100%
  );

  @media (max-width: 320px) {
    padding: 0 2rem;
  }
  .box--section {
    max-width: 140rem;
    width: 100%;
    min-height: 50rem;
    background: var(--color-bg);
    border-radius: 6rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;

    .item {
      padding: 5rem 2rem 2rem;

      position: relative;
      top: -12rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 30%;
      min-width: 28rem;
      align-items: center;
      // justify-content: center;
      @media (max-width: 620px) {
        width: 100%;
      }

      .icon--container {
        width: 15rem;
        height: 15rem;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          color: var(--color-primary);
          font-size: 6rem;
        }
      }
      h3 {
        color: var(--color-secondary);
        text-align: center;
      }
      p {
        color: var(--color-secondary);
        width: 100%;
        text-align: center;
      }
    }
  }
}
