@import "~react-image-gallery/styles/scss/image-gallery.scss";
.details--container {
  .nav {
    margin-bottom: 5rem;
    .products {
      display: none;
    }
  }
  width: 100%;
  padding: 5rem 1rem;
  .details {
    width: 100%;
    max-width: 140rem;
    margin: auto;
    display: flex;
    gap: 2rem;
    @media (max-width: 800px) {
      flex-direction: column;
    }

    .left {
      width: 50%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      @media (max-width: 800px) {
        width: 100%;
      }
      .product-slider {
        width: 100%;

        padding: 1rem;
        position: relative;

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            min-height: 40rem;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .slider-thumbs {
        display: flex;

        gap: 1rem;
        .thumbs {
          width: 10rem;
          cursor: pointer;
          border: 2px solid #dddbdb;
          @media (max-width: 600px) {
            width: 7rem;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
        .thumb-active {
          border-color: var(--color-primary);
        }
      }
    }
    .right {
      width: 50%;
      padding: 3rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
}

.image-gallery {
  height: 100%;
  .image-gallery-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
  }
}
