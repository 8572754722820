.headerSwiper {
  width: 100%;
  max-width: 150rem;
  margin: 3rem auto 5rem;

  height: 100%;

  overflow: hidden;
  .swiper-pagination {
    --swiper-pagination-bullet-inactive-opacity: 0.7;
    @media (max-width: 900px) {
      --swiper-pagination-top: 98%;
    }
  }

  .swiper-pagination-bullet {
    border: none;
    background-color: transparent;
    height: 2rem;
    width: 4.5rem;
    border-radius: 99em;
    background-color: #000;
    background-image: linear-gradient(
      90deg,
      #d7e2f1 50%,
      #d7e2f1 50%,
      #71c9b6 50%,
      #71c9b6 100%
    );
    transform: rotate(-45deg);
    position: relative;
    overflow: hidden;
    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 64px;
      height: 14px;
      border-radius: 99em;
      background-color: rgba(#fff, 0.5);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -150%);
    }

    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 120%;
      height: 100%;
      border-radius: 99em;
      background-color: rgba(#fff, 0.25);
      left: 50%;
      top: 120%;
      transform: translate(-50%, -150%);
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px #2d2d2d;
    }
  }

  .swiper-pagination-bullet-active {
    background-image: linear-gradient(
      90deg,
      #0f2e27 50%,
      #0f2e27 50%,
      #318c78 50%,
      #318c78 100%
    );
  }

  .slide {
    width: 100%;
    height: 100%;
    padding: 1rem;
    margin-bottom: 5rem;
    .content {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @media (max-width: 900px) {
        flex-wrap: wrap;
      }

      // gap: 2rem;
      .col {
        // max-width: 100%;

        justify-content: center;
        display: flex;
        flex-direction: column;

        position: relative;
        .bg {
          position: absolute;
          top: 4rem;
          left: 4rem;
          max-height: 55rem;
          max-width: 90%;
          z-index: -1;
          @media (max-width: 550px) {
            top: 0rem;
            left: 0rem;
          }
        }

        .img {
          max-height: 60rem;
          max-width: 60rem;
          @media (max-width: 1450px) {
            max-height: 50rem;
            max-width: 50rem;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        &.col1 {
          max-width: 30rem;
          gap: 2rem;
          @media (max-width: 720px) {
            margin-top: 4rem;
            text-align: center;
            max-width: 100%;
          }
        }
        &.col3 {
          gap: 5rem;
        }
        .btn span {
          padding: 0 1rem;
        }
        .row {
          display: flex;
          gap: 2rem;
          &--left {
            height: 8rem;
            width: 8rem;
            border-radius: 50%;
            background: linear-gradient(
              to right,
              var(--color-bg),
              var(--color-secondary-lighter)
            );
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              color: rgb(255, 255, 255);
              font-size: 4rem;
            }
          }

          h3 {
            color: var(--color-secondary);
            max-width: 20rem;
          }
        }
      }
    }
    h1 {
      line-height: 1;

      text-align: center;
      width: 100%;
      font-weight: 700;
      font-size: 13rem;
      color: var(--color-primary);
      text-transform: capitalize;
      @media (max-width: 1450px) {
        font-size: 11rem;
        line-height: 1;
      }

      @media (max-width: 1110px) {
        font-size: 10rem;
        line-height: 1;
      }
      @media (max-width: 900px) {
        font-size: 9rem;
      }
      @media (max-width: 750px) {
        font-size: 7rem;
        line-height: 1;
      }
    }
    h3 {
      color: var(--color-text-secondary);
    }
    .col-left {
      color: var(--color-secondary);
      font-size: 3.4rem;

      text-transform: capitalize;
      font-weight: 500;
    }
    p {
      color: var(--color-text-secondary);
      max-width: 20rem;
    }
  }
}
